import { Game } from '../types';

const sortGames = (games: Game[], type: string): Game[] => {
    const types: { [key: string]: string } = {
        name: 'name',
        created_at: 'created_at',
        released_on: 'released_on'
    };

    const sortProperty = types[type];

    const sorted = [...games].sort((a: any, b: any) =>
        a[sortProperty].localeCompare(b[sortProperty])
    );

    return sorted;
};

export default sortGames;
