import React, { useEffect, useState } from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Container, Input, Row } from 'reactstrap';
import { GamesGrid } from '../components/GamesGrid';
import games from '../games';
import { Game, Platform } from '../types';
import filterByPlatform from '../utilities/filterByPlatform';
import sortGames from '../utilities/sortGames';

interface GamesLibraryProps {}

export const GamesLibrary: React.FC<GamesLibraryProps> = () => {
    const [data, setData] = useState<Game[]>([]);
    const [sortType, setSortType] = useState<string>('released_on');
    const [filterPlatform, setFilterPlatform] = useState<Platform>();

    useEffect(() => {
        setData(filterByPlatform(sortGames(games, sortType), filterPlatform));
    }, [sortType, filterPlatform]);

    return (
        <Container>
            <Row className="mb-3">
                <Col lg="8">
                    {filterPlatform && (
                        <Button type="button" onClick={() => setFilterPlatform(undefined)}>
                            {filterPlatform.name} <FontAwesomeIcon icon={faTimes} fixedWidth />
                        </Button>
                    )}
                </Col>
                <Col lg="4">
                    <Input
                        type="select"
                        defaultValue={sortType}
                        onChange={e => setSortType(e.target.value)}>
                        <option value="name">Name</option>
                        <option value="released_on">Release Date</option>
                        <option value="created_at">Added Date</option>
                    </Input>
                </Col>
            </Row>
            <GamesGrid
                games={data}
                onPlatformClick={(platform: Platform) => setFilterPlatform(platform)}
            />
        </Container>
    );
};
