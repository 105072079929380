import React from 'react';
import { Col, Row } from 'reactstrap';
import { Game, Platform } from '../types';
import { GameCard } from './GameCard';

interface GamesGridProps {
    games: Game[];
    onPlatformClick: (platform: Platform) => void;
}

export const GamesGrid: React.FC<GamesGridProps> = props => (
    <Row className="justify-content-center">
        {props.games.map(game => (
            <Col key={game.id} xs="6" md="4" lg="2" className="mb-3 user-select-none">
                <GameCard game={game} onPlatformClick={props.onPlatformClick} />
            </Col>
        ))}
    </Row>
);
