import React from 'react';
import { Container } from 'reactstrap';

interface HeaderProps {
    heading: string;
    subHeading?: string;
}

export const Header: React.FC<HeaderProps> = ({ heading, subHeading }) => (
    <header className="bg-dark py-5 shadow">
        <Container className="px-4 px-lg-5 my-5">
            <div className="text-center text-white">
                <h1 className="display-4 fw-bolder">{heading}</h1>
                {subHeading && <p className="lead fw-normal text-white-50 mb-0">{subHeading}</p>}
            </div>
        </Container>
    </header>
);
