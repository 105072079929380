import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Header } from '../components/Header';
import { Section } from '../components/Section';

interface AboutPageProps {}

export const AboutPage: React.FC<AboutPageProps> = () => {
    return (
        <>
            <Header heading="About" />
            <Section>
                <Container className="px-4">
                    <Row className="gx-4 justify-content-center">
                        <Col lg="8">
                            <p>
                                I have never traded in a computer game in my life! This is because I
                                feel that the amount of money you get for used computer games can be
                                insulting - especially when they have been kept in perfect
                                condition. I still own every computer game I have ever bought or
                                been gifted except for ones I loaned to friends and forgot about,
                                but I've gained a few that way too. A lot of these computer games
                                are from my childhood and have nostalgic value, which is probably
                                why I have held onto them for so long, and as a result I have
                                accumulated many over the years.
                            </p>
                            <p>
                                So, after reading that, you are probably now thinking “this
                                dyerbolicall guy must be quite the gamer” … well, actually no. As a
                                matter of fact, all but the computer games I own for the currently
                                generation of consoles have been kept in storage boxes in the loft.
                                That is until recently!
                            </p>
                            <p>
                                This web app has been built as the accompanying software to my newly
                                built games room in my home. Using{' '}
                                <a href="https://reactjs.org/" rel="noreferrer" target="_blank">
                                    React
                                </a>
                                , I am rendering out each computer game I own for visual reference
                                as well as to give some meta data such as when it was released and
                                where I can find it in my highly organised storage system. So now,
                                whenever I have some free time, I can scroll through my virtual
                                games library and see what catches my eye, find the disc, put it
                                into the console and start to relive some childhood nostalgia or
                                jump back and pick up where I left off!
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    );
};
