import React from 'react';
import { Container } from 'reactstrap';

interface FooterProps {}

export const Footer: React.FC<FooterProps> = () => (
    <footer className="py-5">
        <Container>
            <p className="m-0 text-center text-white">Copyright &copy; dyerbolicall.com 2023</p>
        </Container>
    </footer>
);
