import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Footer } from './components/Footer';
import { Navigation } from './components/Navigation';
import { AboutPage } from './pages/AboutPage';
import { GamesPages } from './pages/GamesPages';

const App: React.FC = () => (
    <>
        <Navigation />
        <Routes>
            <Route path="/about" element={<AboutPage />} />
            <Route path="/" element={<GamesPages />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
        <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
    </>
);

export default App;
