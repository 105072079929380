import React from 'react';
import { Header } from '../components/Header';
import { GamesLibrary } from '../containers/GamesLibrary';

interface GamesPagesProps {}

export const GamesPages: React.FC<GamesPagesProps> = () => {
    return (
        <>
            <Header heading="Games Library" />
            <section className="py-5">
                <GamesLibrary />
            </section>
        </>
    );
};
